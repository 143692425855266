import Tracing from "./Tracing";

(function() {
    if (!window.wlec) {
        return console.error('Tracing needs window.wlec instance');
    }

    if (typeof window.wlec.environment.config_environment !== 'string') {
        return console.error('config_environment not set');
    }

    if (typeof window.wlec.environment.sentry.dsn !== 'string') {
        return console.error('sentry.dsn not set');
    }

    Tracing.getInstace()
        .addDSN(window.wlec.environment.sentry.dsn)
        .setConfig('environment', window.wlec.environment.config_environment)
        .setConfig('component', 'youtix-frontend')
        .setConfig('tracesSampleRate', 1.0)
        .setConfig('debug', window.wlec.environment.sentry.debug === 'true')
        .init();

    window.addEventListener("error", Tracing.errorHandler);
})();
