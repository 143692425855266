import * as Sentry from '@sentry/browser';

/**
 * The Tracing class is used to configure and initialize Sentry for error tracking and performance monitoring.
 */
class Tracing {
  static instance = null;
  /**
   * Configuration object for Sentry.
   * @type {Sentry.BrowserOptions}
   */
  config = {
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
  };

  /**
   * Tags to be added to Sentry events.
   * @type {Object}
   */
  tags = {};

  /**
   * Returns a new instance of the Tracing class.
   * @returns {Tracing} A new instance of Tracing.
   */
  static getInstace() {
    if (this.instance === null) {
      this.instance = new Tracing();
    }

    return this.instance;
  }

  /**
   * Adds a key-value pair to the Sentry configuration.
   * @param {keyof Sentry.BrowserOptions} key - The configuration key.
   * @param {any} value - The configuration value.
   * @returns {Tracing} The current instance of Tracing.
   */
  setConfig(key, value) {
    this.config[key] = value;
    return this;
  }

  /**
   * Adds a DSN (Data Source Name) to the Sentry configuration.
   * @param {string} dsn - The DSN string.
   * @returns {Tracing} The current instance of Tracing.
   */
  addDSN(dsn) {
    this.config.dsn = window.wlec.environment.sentry.dsn || dsn;
    return this;
  }

  /**
   * Adds a tag to be sent with Sentry events.
   * @param {string} key - The tag key.
   * @param {number} value - The tag value.
   * @returns {Tracing} The current instance of Tracing.
   */
  addTag(key, value) {
    this.tags[key] = value;
    return this;
  }

  /**
   * Sets the user information to be sent with Sentry events.
   * @param {Sentry.User} user - The user object.
   */
  static setUser(user) {
    if (typeof user === void 0) {
      return console.error('User must be defined');
    }

    Sentry.setUser(user);
  }

  /**
   * Initializes Sentry with the current configuration and tags.
   */
  init() {
    if (!this.config.dsn) {
      throw new Error('DSN must be defined');
    }

    Sentry.init(this.config);

    for (const key in this.tags) {
      Sentry.setTag(key, this.tags[key]);
    }
  }

  /**
   * Handles errors by logging the event and sending it to Sentry.
   *
   * @param {ErrorEvent} event - The error event object.
   */
  static errorHandler = (event) => {
    Sentry.getCurrentScope().addAttachment({
      filename: event.filename ?? 'unknown',
      type: event.type
    });

    Sentry.captureException(event.error);

    return true;
  }
}

export default Tracing;
